<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row ma-0 max-h-300px">
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.priority"
              label="Priority"
              type="number"
              dense
              outlined
              clearable
              :error-messages="priorityErrors"
              @blur="$v.formData.priority.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-autocomplete
              v-model="formData.type_id"
              label="Type"
              :items="serverData.types"
              @change="onTypeChange"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-autocomplete
              v-model="formData.apply_type_id"
              label="Apply type"
              :items="serverData.apply_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="apply_type_idErrors"
              @blur="$v.formData.apply_type_id.$touch()"
            />
          </div>

          <div
            v-if="formData.apply_type_id === 2"
            class="col-12 col-sm-6 h-75px"
          >
            <v-autocomplete
              v-model="formData.platforms"
              label="Platforms"
              :items="serverData.platforms"
              item-text="text"
              item-value="index"
              dense
              outlined
              multiple
              clearable
              hide-no-data
              hide-selected
              :error-messages="platformsErrors"
              @blur="$v.formData.platforms.$touch()"
            />
          </div>

          <div
            :class="[
              'h-75px',
              formData.apply_type_id === 2 ? 'col-md-6' : 'col-12',
            ]"
          >
            <v-text-field
              v-model="formData.description"
              label="Description"
              dense
              outlined
              clearable
            />
          </div>

          <!-- <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-autocomplete
              v-model="formData.actions"
              label="Actions"
              :items="actions"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="actionErrors"
              @blur="$v.formData.actions.$touch()"
            />
          </div> -->
        </form>

        <div class="mt-4 pa-0 w-100">
          <RuleBuilder
            :query="query"
            :options="operands"
            :operators="operators"
            :userId="userId"
            ref="ruleBuilder"
          />
        </div>
        <div class="mt-4 pa-0 w-100">
          <ActionBuilder
            :query="formData.actions"
            :options="serverData.actions"
            :operators="serverData"
            :userId="userId"
            ref="actionBuilder"
          />
        </div>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import RuleBuilder from "@/own/components/orderManagement/messageProcessRules/ruleBuilder/RuleBuilder";
import ActionBuilder from "@/own/components/orderManagement/messageProcessRules/actionBuilder/ActionBuilder";
import { SET_COURIER_TYPE } from "@/core/services/store/messageProcessRules.module";
import Swal from "sweetalert2";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { RuleBuilder, ActionBuilder },
  validations: {
    formData: {
      name: { required },
      status: { required },
      priority: { required },
      // actions: { required },
      apply_type_id: { required },
      platforms: {
        required: requiredIf(function () {
          return this.formData.apply_type_id === 2;
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    clear_counter: false,
    formData: {
      name: null, //++
      priority: null, //++
      status: null, //++
      description: null, //++
      // actions: 1,
      actions: [],
      type_id: 1,
      apply_type_id: null,
      platforms: [],
    },
    query: {
      condition: "and",
      rules: [],
    },
  }),
  methods: {
    checkActionsAreUnique() {
      for (let i = 0; i < this.formData.actions.length - 1; i++) {
        for (let j = i + 1; j < this.formData.actions.length; j++) {
          if (this.formData.actions[i].id == this.formData.actions[j].id) {
            return false;
          }
        }
      }
      return true;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
        this.clear_counter = false;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.formData = {
        name: null,
        priority: null,
        status: null,
        description: null,
        // actions: 1,
        actions: [],
        type_id: 1,
        apply_type_id: null,
      };
      this.query = {
        condition: "and",
        rules: [],
      };
    },
    loadDataFromServer() {
      // this.$store.dispatch(GET_DATA);
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        return ApiService.post("/datahub/message_process_rules/edit", sendData)
          .then(({ data }) => {
            if (data.message_process_rule.condition)
              this.query = JSON.parse(data.message_process_rule.condition);
            delete data.message_process_rule.condition;
            this.serverData = data;
            this.formData = data.message_process_rule;
          })
          .then(() => {
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/datahub/message_process_rules/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    validateActionBuilderData() {
      let isNotValid = false;
      this.formData.actions.forEach((attr) => {
        if (!attr.value) {
          isNotValid = true;
        }
      });
      return isNotValid;
    },
    submitForm() {
      this.$v.$touch();
      // console.log("sendData", this.query);
      // if (this.formData.type_id === 2) {
      if (!this.checkActionsAreUnique()) {
        Swal.fire({
          title: "Warning",
          text: "Actions attributes must be unique!",
          icon: "warning",
        });
        return;
      }
      if (!this.query.rules.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one rule!",
          icon: "warning",
        });
        return;
      }

      // if (
      //   this.$v.$invalid ||
      //   this.$store.getters.getMESSAGEPROCESSRULESNotValid
      // ) {
      //   console.log(
      //     this.$v.$invalid,
      //     this.$store.getters.getMESSAGEPROCESSRULESNotValid
      //   );
      //   return;
      // }
      //else console.log("noooo");
      // }
      // if (this.formData.actions === 3) {
      if (!this.formData.actions.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one action!",
          icon: "warning",
        });
        return;
      }
      if (this.validateActionBuilderData()) {
        // console.log("aaaaaa");
        return;
      }
      // } else {
      //   this.formData.attributes = [];
      // }
      // if (this.$v.$invalid) {
      //   console.log("form invalid");
      //   return;
      // }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
        // actions: [this.formData.actions], //spartanskiyy
      };

      // if (this.formData.type_id === 2) {
      sendData.condition = JSON.stringify(this.query);
      // }
      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      // console.log("sendData", sendData);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/datahub/message_process_rules/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    // toggle() {
    //   if (this.selectedAll) {
    //     this.$set(this.formData, "clients", null);
    //   } else {
    //     let arr = [...this.customers];
    //     this.$set(this.formData, "clients", arr);
    //   }
    // },
    onTypeChange() {
      this.$store.commit(SET_COURIER_TYPE, this.formData.type_id);
    },
  },
  computed: {
    icon() {
      if (this.selectedAll) return "mdi-checkbox-marked";
      if (this.selectedSome) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    userId: function () {
      return this.$store.getters.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.clients;
    },
    customers: function () {
      return this.serverData.clients;
    },
    statusValues: function () {
      return this.serverData.statuses;
    },
    // ruleTypes: function () {
    //   return this.serverData.types;
    // },
    actions: function () {
      return this.serverData.actions;
    },
    operands: function () {
      return this.formData.type_id === 3
        ? this.serverData.returnOperands
        : this.serverData.operands;
    },
    returnOperands: function () {
      return this.serverData.returnOperands;
    },
    operators: function () {
      return this.serverData.operators;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("clients");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    priorityErrors: function () {
      return this.handleFormValidation("priority");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    // typeErrors: function () {
    //   return this.handleFormValidation("type_id");
    // },
    // actionErrors: function () {
    //   return this.handleFormValidation("actions");
    // },
    apply_type_idErrors: function () {
      return this.handleFormValidation("apply_type_id");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
    platformsErrors() {
      return this.handleFormValidation("platforms");
    },
  },
  watch: {
    // "formData.type_id": function () {
    //   this.query = {
    //     condition: "and",
    //     rules: [],
    //   };
    // },
  },
};
</script>
